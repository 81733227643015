import * as React from "react"
import DocumentationContent from "../../components/DocumentationContent"
import DocumentationSidebar from "../../components/DocumentationSidebar"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import RelatedContent from '../../components/RelatedContent'
import Content, { HTMLContent } from '../../components/Content'
import Documentation from '../../components/Documentation'
import { CopyBlock, CodeBlock, atomOneDark, solarizedDark, xt256 } from "react-code-blocks";
import {Image, SlideshowLightbox} from "lightbox.js-react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import DemoSidebar from "../../components/DemoSidebar";

export default function DarkThemeCitiesDemoComponent () {
  const cityImages = [{title: 0, src: "https://source.unsplash.com/pAKCx4y2H6Q/1400x1200", caption: "Street at night"},
  {title: 1, src: "https://source.unsplash.com/AYS2sSAMyhc/1400x1200", caption: "Cyberpunk night"},
  {title: 2, src: "https://source.unsplash.com/Kk8mEQAoIpI/1600x1200", caption: "City night"},
  {title: 3, src: "https://source.unsplash.com/uqQLthPDCbg/1600x1200", caption: "Neon city"},
  {title: 4, src: "https://source.unsplash.com/ce-K_rj1P7U/1600x1200", caption: "Neon night"},
  {title: 5, src: "https://source.unsplash.com/GmLfS_S43gA/1600x1200", caption: "Neon night"}

];

const cityImages2 = [{title: 0, src: "https://images.pexels.com/photos/1470405/pexels-photo-1470405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Street at night"},
{title: 1, src: "https://images.pexels.com/photos/2693282/pexels-photo-2693282.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Cyberpunk night"},
{title: 2, src: "https://images.pexels.com/photos/315191/pexels-photo-315191.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "City night"},
{title: 3, src: "https://images.pexels.com/photos/2303337/pexels-photo-2303337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon city"},
{title: 4, src: "https://images.pexels.com/photos/2339009/pexels-photo-2339009.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon night"},
{title: 5, src: "https://images.pexels.com/photos/2434627/pexels-photo-2434627.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2", caption: "Neon night"}

];
  const showLineNumbers = true;
  const wrapLines = true;
  const codeBlock = false;

  return <Layout>
    <section className="section section--gradient font-inter bg_white">
      <Header overrideDarkTheme={true} />
    
        <div className="pt-4">
        <main className="flex-grow">

            <section>
            <div className="max-w-6xl mx-auto px-2 sm:px-2">
                <div className="pt-32 pb-12 md:pt-40 md:pb-20">

                {/* Main content */}
                <div className="md:flex md:justify-between" data-sticky-container>

                    <DemoSidebar />

                    <div className="md:flex-grow  font-inter">

                      {/* <Header /> */}
                      <div className="text-lg text-gray-600">
                        <h1 className="h1 text-gray-900 mb-4 font-bold">Photo Showcase</h1>
                             <SlideshowLightbox theme="night" imgAnimation="fade" showThumbnails={true} roundedImages={true} 
                             className="container grid grid-cols-3 gap-2 mx-auto"
                             > 
                              <img className="w-full rounded" src={cityImages2[0].src} />
                              <img className="w-full rounded object-cover" src={cityImages2[1].src} />  
                              <img className="w-full rounded object-cover" src={cityImages2[2].src} />
                              <img className="w-full rounded" src={cityImages2[3].src} />       

                              <img className="w-full rounded" src={cityImages2[4].src} />
                              <img className="w-full rounded" src={cityImages2[5].src} />   
                                      
                            </SlideshowLightbox>


      </div>

      {/* Related content */}
        <RelatedContent />
      {/* <Feedback /> */}

    </div>

                </div>

                </div>
            </div>
            </section>

            </main>
        </div>

    </section>
    <Footer overrideDarkTheme={true} />
    </Layout>
    
}